<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-window"/>
          Bestehende Leistung ändern
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="updateLeistung()">

              <FreizeitText v-model="name" formLabel="Name"/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Leistung ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import FreizeitText from '@/components/FormElements/Text'

export default {
  name: 'MitarbeiterEdit',
  components: {
    FormError,
    FreizeitText
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      name: '',
      formError: null
    }
  },
  mounted () {
    if (this.uuid) {
      Vue.axios.get('/leistung/get/' + this.uuid)
        .then((response) => {
          this.name = response.data.name
        })
    }
  },
  methods: {
    updateLeistung () {
      Vue.axios.put('/leistung/put/' + this.uuid, {
        name: this.name
      }).then((response) => {
        this.$router.push({ path: '/leistungen/uebersicht' })
        this.$snotify.success('Die Leistung wurde angepasst.', {
          position: 'rightTop',
          timeout: 4000
        })
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
